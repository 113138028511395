import React from "react";
import { Link } from "react-router-dom";
import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedin, FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt, FaYoutube } from "react-icons/fa";

import CompanyImage from "../../Assets/AgileLogoLight.png";

import "./Footer.css";

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This adds a smooth scrolling effect
    });
  };
  return (
    <div className="main-footer-container">
      <div className="footer-content-items">
        <div className="industry">
          <h3>CONTACT US</h3>
          <Link onClick={handleScrollToTop} to="/" className="link">
            <div className="footer-logo-content-container">
              <img
                src={CompanyImage}
                className="CompanyImage"
                alt="company-img"
              />
              <h4
                style={{ color: "rgb(53,184,175)" }}
                className="footer-company-name"
              >
                Agile TechAI
              </h4>
            </div>
          </Link>
          <div className="visit-address-container">
            <a
              href="https://www.google.com/maps/place/1401+Pennsylvania+Ave+105+6024,+Wilmington,+DE+19806,+USA/@39.7540601,-75.5637511,17z/data=!3m1!4b1!4m5!3m4!1s0x89c6fd11f2a9c1c9:0x4ed58c5115a33489!8m2!3d39.7540601!4d-75.5611762?entry=ttu&g_ep=EgoyMDI1MDEwOC4wIKXMDSoASAFQAw%3D%3D"
              className="visit-address"
            >
              <IoLocationSharp style={{ marginRight: "5px" }} />
              1401 PENNSYLVANIA AVE SUITE 105 - #6024 WILMINGTON
              , Delaware 19806
            </a>
            <div className="visit-address-container">
              <a
                href="tel:2347046270"
                className="visit-address"
                style={{ marginTop: "" }}
              >
                <FaPhoneAlt style={{ marginRight: "5px" }} />
                2347046270
              </a>
            </div>
            <div className="visit-address-container">
              <a
                href="mailto:info@agiletechai.com"
                className="visit-address"
                style={{ marginTop: "15px" }}
              >
                <IoIosMail style={{ marginRight: "5px" }} />
                info@agiletechai.com
              </a>
            </div>
          </div>
          <div className="contact-icons-container">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.linkedin.com/in/agile-techai"
            >
              <FaLinkedin />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.youtube.com/@AgileTechAi"
            >
              <FaYoutube />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/profile.php?id=61565508022242"
            >
              <FaFacebookSquare />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://x.com/AgileTechai"
            >
              <FaSquareXTwitter />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/agiletechai/"
            >
              <RiInstagramFill />
            </a>
          </div>
        </div>

        <div className="company">
          <h3>COMPANY</h3>
          <ul>
            <Link className="footer-item" to="/" onClick={handleScrollToTop}>
              <li>Home</li>
            </Link>
            <Link
              className="footer-item"
              to="/about"
              onClick={handleScrollToTop}
            >
              <li>About</li>
            </Link>
            <Link
              className="footer-item"
              to="/industry"
              onClick={handleScrollToTop}
            >
              <li>Industry</li>
            </Link>
            <Link
              className="footer-item"
              to="/services"
              onClick={handleScrollToTop}
            >
              <li>Services</li>
            </Link>
            <Link
              className="footer-item"
              to="/careers"
              onClick={handleScrollToTop}
            >
              <li>Careers</li>
            </Link>
            <Link
              className="footer-item"
              to="/contact"
              onClick={handleScrollToTop}
            >
              <li>Contact Us</li>
            </Link>
          </ul>
        </div>

        <div className="industry">
          <h3>INDUSTRY</h3>
          <ul>
            <Link
              className="footer-item"
              to="/industry/bank-financial"
              onClick={handleScrollToTop}
            >
              <li>Banking</li>
            </Link>
            <Link
              className="footer-item"
              to="/industry/healthcare"
              onClick={handleScrollToTop}
            >
              <li>Healthcare</li>
            </Link>
            <Link
              className="footer-item"
              to="/industry/e-commerce"
              onClick={handleScrollToTop}
            >
              <li>E-commerce</li>
            </Link>
            <Link
              className="footer-item"
              to="/industry/hotel-hospitality-solutions"
              onClick={handleScrollToTop}
            >
              <li>Hotel & Hospitality</li>
            </Link>
          </ul>
        </div>

        <div className="industry">
          <h3>SERVICES</h3>
          <ul>
            <Link
              className="footer-item"
              to="/services/cloud"
              onClick={handleScrollToTop}
            >
              <li>Cloud Services</li>
            </Link>
            <Link
              className="footer-item"
              to="/services/Data-analytics"
              onClick={handleScrollToTop}
            >
              <li>Data & Analytics</li>
            </Link>
            <Link
              className="footer-item"
              to="/services/artificial-intelligence-automation"
              onClick={handleScrollToTop}
            >
              <li>Artificial Intelligence & Automation</li>
            </Link>
          </ul>
        </div>
      </div>
      <div class="footer-terms-content">
        <ul class="footer-terms-links">
          <Link
            onClick={handleScrollToTop}
            style={{ fontSize: "12px" }}
            className="link"
            to="/terms-and-conditions"
          >
            <li>Terms & Conditions</li>
          </Link>
          <Link
            onClick={handleScrollToTop}
            style={{ fontSize: "12px" }}
            className="link"
            to="/privacy-policy"
          >
            <li>Privacy Policy</li>
          </Link>
        </ul>
        <p className="copy">&copy; 2024 Agile TechAI. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
