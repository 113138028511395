import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
import { FaAngleRight, FaAngleDown } from "react-icons/fa6";

import BankingImg from "../../Assets/banking.jpg";
import HealthImg from "../../Assets/health.jpg";
import EcomImg from "../../Assets/ecom.jpg";
import HotelImg from "../../Assets/hotel.jpg";


import "./Banking/Banking.css";
import "./Healthcare/Healthcare.css";
import "./Ecommerce/Ecommerce.css";
import "./Hotel/Hotel.css";


import "./Industry.css";

const Industry = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSubheading = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const id = params.get("id");

  return (
    <div id="industry" className="industry-main-container">
      <h1>Industry Solutions</h1>
      <div>
        {/* Bank  */}
        <div className="industry-item" onClick={() => toggleSubheading(0)}>
          <div className="drop-item">
            <div>
              <h3>Banking</h3>
              <p>Innovative solutions for financial institutions.</p>
            </div>
            {activeIndex === 0 ? <FaAngleDown /> : <FaAngleRight />}
          </div>
          {activeIndex === 0 && (
            <div className="bank-main-container-drop">
              <h3 className="sm-heading">Banking: </h3>

              <div className="banking-content">
                <img
                  className="banking-img"
                  src={BankingImg}
                  alt="banking-img"
                />

                <div>
                  <h3 className="lg-heading">Banking: </h3>
                  <p>
                    At Agile TechAI, we understand the complexities and
                    regulatory demands of the banking and financial sector. Our
                    expertise lies in delivering innovative, secure, and
                    compliant software solutions that empower financial
                    institutions to stay ahead in a rapidly evolving industry.
                  </p>
                </div>
              </div>
              <div>
                <h4>Overview of Services:</h4>
                <ul className="bank-list-items">
                  <li>
                    <span>Custom Software Development:</span> Tailored solutions
                    that meet the unique needs of banking and financial
                    institutions, from core banking systems to mobile banking
                    applications.
                  </li>
                  <li>
                    <span>Digital Transformation:</span> Modernize legacy
                    systems and adopt cloud-based solutions to enhance agility,
                    efficiency, and customer engagement.
                  </li>
                  <li>
                    <span>Risk Management and Compliance:</span> Implement
                    robust solutions that ensure regulatory compliance, data
                    security, and risk management across all operations.
                  </li>
                  <li>
                    <span>Blockchain and FinTech Integration:</span> Harness the
                    power of blockchain technology and FinTech innovations to
                    offer cutting-edge financial services and products.
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        {/* health */}
        <div className="industry-item-b" onClick={() => toggleSubheading(1)}>
          <div className="drop-item">
            <div>
              <h3>Healthcare</h3>
              <p>Advanced healthcare technology solutions.</p>
            </div>
            {activeIndex === 1 ? <FaAngleDown /> : <FaAngleRight />}
          </div>
          {activeIndex === 1 && (
            <div className="health-main-container-drop">
              <h3 className="sm-heading">Healthcare:</h3>

              <div className="health-content">
                <img className="health-img" src={HealthImg} alt="" />
                <div>
                  <h3 className="lg-heading">Healthcare:</h3>
                  <p>
                    At Agile TechAI, we are dedicated to transforming the
                    healthcare industry through innovative software solutions
                    and advanced artificial intelligence. Our mission is to
                    empower healthcare providers, payers, and patients with
                    technology that enhances care delivery, improves outcomes,
                    and optimizes operations.
                  </p>
                </div>
              </div>
              <div>
                <h4>Overview of Services:</h4>
                <ul className="health-list-items">
                  <li>
                    <span>Custom Healthcare Software Development:</span> We
                    design and develop tailor-made solutions that address the
                    specific needs of healthcare organizations, including
                    electronic health records (EHR) systems, patient management
                    software, and telemedicine platforms.
                  </li>
                  <li>
                    <span>
                      Healthcare Data Integration and Interoperability:
                    </span>{" "}
                    Facilitate seamless data exchange across systems and
                    platforms to ensure that healthcare providers have access to
                    comprehensive patient information when and where they need
                    it.
                  </li>
                  <li>
                    <span>Patient Engagement and Telehealth:</span> Enhance
                    patient engagement through user friendly telehealth
                    solutions, mobile health apps, and patient portals that
                    offer remote care and continuous communication between
                    providers and patients.
                  </li>
                  <li>
                    <span>Compliance and Security: </span>Ensure that all
                    solutions comply with healthcare regulations like HIPAA, and
                    implement robust security measures to protect sensitive
                    patient data from breaches and unauthorized access.
                  </li>
                  <li>
                    <span>Healthcare Analytics:</span> Provide advanced
                    analytics solutions that help healthcare organizations gain
                    insights into patient care, operational efficiency, and
                    financial performance.
                  </li>
                </ul>
              </div>
              <p>
                By partnering with Agile TechAI, healthcare organizations gain
                access to innovative technology that not only meets the demands
                of today’s healthcare landscape but also positions them for
                future success. Our solutions are designed to enhance the
                quality of care, streamline operations, and improve the patient
                experience, all while ensuring the highest levels of data
                security and compliance.{" "}
              </p>
            </div>
          )}
        </div>

        {/* ecom */}
        <div className="industry-item" onClick={() => toggleSubheading(2)}>
          <div className="drop-item">
            <div>
              <h3>E-commerce</h3>
              <p>Scalable and secure e-commerce platforms.</p>
            </div>
            {activeIndex === 2 ? <FaAngleDown /> : <FaAngleRight />}
          </div>
          {activeIndex === 2 && (
            <div className="ecom-main-container-drop">
              <h3 className="sm-heading">E-commerce:</h3>
              <div className="ecom-content">
                <img className="ecom-img" src={EcomImg} alt="" />
                <div>
                  <h3 className="lg-heading">E-commerce:</h3>
                  <p>
                    At Agile TechAI, we specialize in creating powerful,
                    scalable, and secure e-commerce solutions that drive
                    business growth and enhance customer experiences. Our
                    expertise in software development and artificial
                    intelligence allows us to craft tailored solutions that meet
                    the unique needs of online retailers, marketplaces, and
                    digital commerce platforms.
                  </p>
                </div>
              </div>
              <div>
                <h4>Overview of Services:</h4>
                <ul className="ecom-list-items">
                  <li>
                    <span>Custom E-commerce Platform Development:</span> We
                    build bespoke e-commerce platforms that are feature-rich,
                    user-friendly, and designed to scale with your business.
                    Whether you're a startup or an established retailer, we
                    provide solutions that are tailored to your specific
                    requirements.
                  </li>
                  <li>
                    <span>AI-Driven Personalization:</span> Enhance your
                    customer’s shopping experience with AI powered
                    personalization engines. Our solutions deliver personalized
                    product recommendations, dynamic pricing, and targeted
                    marketing, increasing conversion rates and customer loyalty.
                  </li>
                  <li>
                    <span>Mobile Commerce Solutions: </span> Develop responsive
                    and high-performance mobile apps that offer a seamless
                    shopping experience across devices. Our mobile commerce
                    solutions are optimized for speed, usability, and
                    integration with existing systems.
                  </li>
                  <li>
                    <span>
                      Integration with Payment Gateways and Logistics:
                    </span>{" "}
                    Ensure smooth transactions and efficient order fulfillment
                    by integrating your e-commerce platform with popular payment
                    gateways, shipping carriers, and inventory management
                    systems.
                  </li>
                  <li>
                    <span>E-commerce Analytics and Business Intelligence:</span>{" "}
                    Leverage advanced analytics to gain insights into customer
                    behavior, sales trends, and inventory management. Our BI
                    tools help you make data-driven decisions to optimize
                    operations and boost profitability.
                  </li>
                  <li>
                    <span>Omni-Channel Retail Solutions: </span>Provide a
                    consistent and cohesive shopping experience across all
                    channels, whether online, in-store, or on mobile. Our
                    omni-channel solutions help you connect with customers
                    wherever they are, ensuring a unified brand experience.
                  </li>
                  <li>
                    <span>Security and Compliance:</span>Protect your e-commerce
                    platform and customer data with top-tier security solutions.
                    We implement secure payment processing, data encryption, and
                    compliance with global e-commerce regulations like PCI DSS.
                  </li>
                  <li>
                    <span>
                      Content Management Systems (CMS) for E-commerce:
                    </span>
                    Easily manage your online store’s content, products, and
                    promotions with user-friendly CMS solutions. We offer
                    customized CMS integrations that allow you to control your
                    e-commerce site with ease.
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        
        {/* hotel */}
        <div className="industry-item-b" onClick={() => toggleSubheading(3)}>
          <div className="drop-item">
            <div>
              <h3>Hotel & Hospitality</h3>
              <p>Comprehensive solutions for the hospitality industry.</p>
            </div>
            {activeIndex === 3 ? <FaAngleDown /> : <FaAngleRight />}
          </div>
          {activeIndex === 3 && (
            <div className="hotel-main-container-drop"> 
            <h3 className="sm-heading">Hotel & Hospitality: </h3>
            <div className="hotel-content">
              <img className="hotel-img" src={HotelImg} alt="hotel-img" />
              <div>
                <h3 className="lg-heading">Hotel & Hospitality:</h3>
                <p>
                  At Agile TechAi, we are dedicated to delivering innovative software
                  solutions that cater to the unique needs of the hotel and
                  hospitality industry. Our expertise lies in enhancing guest
                  experiences, optimizing operations, and driving revenue growth
                  through cutting-edge technology.
                </p>
              </div>
            </div>
            <div>
              <h4>Overview of Services:</h4>
              <ul className="hotel-list-items">
                <li>
                  <span>Customer Relationship Management (CRM):</span> In the
                  hospitality industry, understanding and anticipating guest needs is
                  key to success. Our CRM solutions are designed to help you create
                  personalized experiences that keep guests coming back. With our
                  systems, you can:
                  <ul>
                    <li>
                      Personalize Guest Interactions: Use detailed guest profiles to
                      tailor communications, offers, and services to individual
                      preferences.
                    </li>
                    <li>
                      Automate Marketing Campaigns: Run targeted email and SMS
                      campaigns to engage guests before, during, and after their stay.
                    </li>
                    <li>
                      Streamline Operations: Integrate CRM with your property
                      management system (PMS) to manage bookings, check-ins, and other
                      guest interactions seamlessly.
                    </li>
                  </ul>
                </li>
                <li>
                  <span>Data Analytics & Reporting:</span> Harness the power of data
                  to transform your hospitality business. Our data analytics and
                  reporting services provide:
                  <ul>
                    <li>
                      Actionable Insights: Analyze guest behavior, market trends, and
                      operational efficiency to make data-driven decisions.
                    </li>
                    <li>
                      Real-Time Reporting: Access up-to-date reports on occupancy,
                      revenue, and guest satisfaction to monitor performance and make
                      timely adjustments.
                    </li>
                    <li>
                      Predictive Analytics: Forecast demand, optimize pricing
                      strategies, and improve resource allocation based on predictive
                      models.
                    </li>
                  </ul>
                </li>
                <li>
                  <span>Cybersecurity Solutions:</span> In an industry where guest
                  trust is paramount, cybersecurity cannot be compromised. Our
                  cybersecurity solutions offer:
                  <ul>
                    <li>
                      Comprehensive Protection: Safeguard sensitive guest data and
                      financial information with advanced encryption and security
                      protocols.
                    </li>
                    <li>
                      Threat Detection and Response: Implement proactive monitoring
                      and rapid response strategies to protect against potential
                      threats and breaches.
                    </li>
                    <li>
                      Compliance Assurance: Ensure compliance with data protection
                      regulations like GDPR and PCI DSS, avoiding penalties and
                      reputational damage.
                    </li>
                  </ul>
                </li>
                <li>
                  <span>IT Support & Managed Services:</span> Let us handle your
                  technology needs so you can focus on hospitality. Our IT support and
                  managed services include:
                  <ul>
                    <li>
                      24/7 Technical Support: Access round-the-clock support to
                      resolve IT issues quickly and efficiently.
                    </li>
                    <li>
                      System Maintenance: Keep your IT infrastructure up-to-date and
                      running smoothly with regular maintenance and updates.
                    </li>
                    <li>
                      Managed IT Services: Outsource your IT operations to us,
                      allowing your internal team to concentrate on strategic
                      initiatives and guest services.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <p>
              By partnering with Agile TechAi, hotel and hospitality businesses gain
              access to innovative technology that enhances guest satisfaction,
              streamlines operations, and drives growth. Our solutions are tailored to
              meet the specific needs of your industry, ensuring that your business
              stays ahead in a competitive market.
            </p>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Industry;
